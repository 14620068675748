
#root>.ms, .modal.ms {
    --bs-primary-rgb: 232, 148, 26;
    --bs-secondary-rgb: 82, 82, 84;

    $primary: #E8941A;
    $secondary: #525254;

    @import 'shared-after';
}

#claimsModal-ms, .offcanvas-lg.ms {
    --bs-primary-rgb: 232, 148, 26;
    --bs-secondary-rgb: 82, 82, 84;

    $primary: #E8941A;
    $secondary: #525254;
}