.step-container {
    .step {
        margin-bottom: 0.75rem;
        &.active {
            font-size: 20px;
        }
        &:last-child {
            color: #9F9E9A;
        }
    }
    .step-divider {
        height: 32px;
        width: 65px;
        border-color: #86888A !important;
        margin-bottom: 0.75rem;
    }
}