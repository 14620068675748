.app-header {
    background-color: white;
    margin-bottom: -18%;
}

.app-container {
    background-color: #F8F9FA;
}

.starIcon {
    width: 1.8em !important;
    height: 1.8em !important;
}