body {
    background-color: #F8F9FA;
}

.text-light-gray {
  color: $light-gray !important;
}

.text-dark-gray {
  color: $dark-gray !important;
}

/* Provide sufficient contrast against white background */
a {
  color: #0366d6;
}

h1 {
  font-size: 34px;
}

.form-floating>label {
  color: $light-gray;
}

$dayWidth: 65px;
/*pixel square for each day - default is 39 */
$width: $dayWidth * 7;
$dayWidthMobile: 14%;
/*pixel square for each day - default is 39 */
$widthMobile: 100%;

.flatpickr-calendar {
  width: $widthMobile;
  margin: 0 auto;
  border-radius: 32px;

  @include media-breakpoint-up(sm) {
    width: $width;
  }
}

.dayContainer {
  width: $widthMobile;
  min-width: $widthMobile;
  max-width: $widthMobile;

  @include media-breakpoint-up(sm) {
    width: $width;
    min-width: $width;
    max-width: $width;
  }
}

.flatpickr-innerContainer,
.flatpickr-days {
  border-bottom-left-radius: 32px;
  border-bottom-right-radius: 32px;
}

.flatpickr-days {
  width: $widthMobile;

  @include media-breakpoint-up(sm) {
    width: $width;
  }

  border: none;
}

.flatpickr-day {
  max-width: $dayWidthMobile;
  height: 6vh;
  line-height: 6vh;

  @include media-breakpoint-up(sm) {
    max-width: $dayWidth;
    height: $dayWidth;
    line-height: $dayWidth;
  }

}

.flatpickr-month,
.flatpickr-weekday,
.flatpickr-weekdays,
.flatpickr-monthDropdown-months {
  background-color: $white !important;
}

.location-card,
.transportation-card,
.type-of-help-card,
input {
    & .btn, & svg {
        transition: color 0.3s ease-in-out, background-color 0.3s ease-in-out, border-color 0.3s ease-in-out, box-shadow 0.3s ease-in-out !important;
    }
    transition: color 0.3s ease-in-out, background-color 0.3s ease-in-out, border-color 0.3s ease-in-out, box-shadow 0.3s ease-in-out !important;
}

.form-control.is-valid,
.form-select.is-valid {
  background-image: none !important;
}

.confirmation-page {
  .navbar {
    margin-bottom: 0 !important;
  }

  &.body-container {
    padding: 0 !important;
    max-width: none !important;

    .header-bg {
      background-color: #F9F7F9;
    }

    h1 {
      margin-bottom: 0;
      padding-bottom: 1rem;
      padding-top: 3rem;
    }

    .divider-end {
      border-right: 4px solid #F2F2F5;
    }

    .confirmation-card {
      &.reservation {
        margin-top: -68px;
      }

      &.insurance {
        margin-top: 48px;

        @include media-breakpoint-up(lg) {
          margin-top: -68px;
        }
      }
    }
  }
}



.form-floating label {
  pointer-events: none;
}

.input-group .braintree-hosted {
    flex-grow: 1;
    .form-control {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        flex-grow: 1;
    }
}
.braintree-hosted {

  .form-floating>.form-control:focus~label,
  .form-floating>.form-control:not(:placeholder-shown)~label,
  .form-floating>.form-select~label {
    opacity: 1;
    transform: none;
    pointer-events: none;
  }

  .form-floating>.form-control {
    height: 58px;
    padding: 6px 12px;
  }

  .form-floating>.form-control.focused,
  .form-floating>.form-control.non-empty {
    padding-top: 1.625rem;
  }

  .form-floating>.form-control.focused~label,
  .form-floating>.form-control.non-empty~label {
    opacity: 0.65;
    transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
  }
}

.form-floating > .form-control:focus ~ label, .form-floating > .form-control:not(:placeholder-shown) ~ label, .form-floating > .form-select ~ label {
    opacity: .65;
}

.move-summary .border-bottom:last-child {
  border-bottom: none !important;
}

.customerSignature {
  font-family: 'Dancing Script', cursive;
  font-size: 1.8em;
}

.main-title {
    font-size: 20px;
    line-height: 24px;
    @include media-breakpoint-up(md) {
        font-size: 28px;
        line-height: 34px;
    }
    @include media-breakpoint-up(lg) {
        font-size: 32px;
        line-height: 38px;
    }
}

.main-subtitle {
    font-size: 16px;
    line-height: 19px;
    @include media-breakpoint-up(md) {
        font-size: 20px;
        line-height: 24px;
    }
}

.heading-row {
    @include media-breakpoint-up(md) {
        align-items: center;
    }
}

.heavy-item-check {
    font-size: 24px;
    @include media-breakpoint-up(md) {
        font-size: 2rem;
    }
}

textarea#moveDescription {
    height: inherit !important;
}

.help-icon {
    background-color: $dark-gray;
    border: 1px solid $dark-gray;
    color: white;
    @include media-breakpoint-up(lg) {
        font-size: 14px;
        background-color: #F0F0F0;
        border: 1px solid #DEE2E6;
        color: $dark-gray;
    }
    svg {
        font-size: 16px;
        @include media-breakpoint-up(lg) {
            font-size: 14px;
        }
    }
}

.payment-callout {
    background-color: #F0F0F0;
    border: 1px solid #DEE2E6;
}

.spinner-smaller {
    width: .75rem;
    height: .75rem;
    border-width: 0.2em;
}

.review-box {
    border: solid 1px #C4C4C4;
    border-radius: .25rem;
    background-color: #FFF;
    padding: 1em;
}

@media screen and (min-width: 768px) {
    .review-box-conditional {
        border: solid 1px #C4C4C4;
        border-radius: .25rem;
        background-color: #FFF;
        padding: 1em;
    }
}

.terms-wrapper {
    background-color: #fff;
}

.drop-shadow {
    box-shadow: 1px 2px 6px rgba(0, 0, 0, 0.12);
}

// Summary Carousel
.carousel {
    .carousel-indicators {
        bottom: -2.5em;
    }
    .carousel-item {
        border: solid 1px #DEE2E6;
        border-radius: .5rem;
        background-color: #F0F0F0;
        padding: 1em;
    }
}

// Accordian hack
.accordion-button:not(.collapsed) {
    background-color: #FFF;
    color: #4D4D4F;

    &::after {
        background-image: url("data: image/svg+xml, %3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%234D4D4F' %3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z' /%3e%3c/svg%3e")
    }
}

.accordion-button:after {
    order: -1; //swap order
    margin-left: 0;
    margin-right: 1rem; // just extra space between text and icon
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%234D4D4F'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}

.brand-logo {
    height: 30px;
    width: auto;
    @include media-breakpoint-up(lg) {
        height: 40px;
    }
}