$theme-colors: ("primary": $primary,
    "secondary": $secondary,
    "dark-gray": $dark-gray,
    "light-gray": $light-gray,
    "headings-color": $headings-color,
    "body-color": $body-color,
    "success": $success,
);


.btn-primary {
    @include button-variant($primary, $primary);
}


.btn-secondary {
    @include button-variant($secondary, $secondary);
}

.btn-dark-gray {
    @include button-variant($dark-gray, $dark-gray);
}

.btn-outline-primary {
    @include button-outline-variant($primary);
}


.btn-outline-secondary {
    @include button-outline-variant($secondary);
}

.btn-check:checked + .btn-outline-secondary, .btn-check:active + .btn-outline-secondary, .btn-outline-secondary:active, .btn-outline-secondary.active, .btn-outline-secondary.dropdown-toggle.show {
    color: $white;
    background-color: $secondary;
}


.border-primary {
    border-color: $primary !important;
}

input:hover,
select:hover,
select.is-valid {
    border-color: $primary !important;
}



.confirmation-page {

    &.body-container {

        .phone-icon path {
            fill: $primary;
        }


    }
}

.flatpickr-day {

    &.selected,
    &.selected:hover {
        background-color: $secondary;
        border-color: $secondary;
    }
}

.location-card.active,
.transportation-card,
.type-of-help-card {
    &[aria-invalid='true'] {
        border-color: #dc3545 !important;
        ~ .invalid-feedback {
            display: block;
        }
    }
    &:hover {
        border-color: $primary !important;
    }
    &:active, &:hover {
        box-shadow: 3px 3px 12px rgba(0, 0, 0, 0.12);
    }
}

.location-card,
.transportation-card,
.address-type-card {
    &:not(.selected):hover {
        cursor: pointer;
    }
}

.location-card {
    &#Unloading .move-details-icon {
        transform: scaleX(-1);

    }
}

.location-card:hover,
.transportation-card.active,
.type-of-help-card.active,
input.is-valid,
select.is-valid {
    border-color: $primary !important;
    .fa-lg, .fa-person-carry {
        color: $white !important;
    }
    .fa-circle {
        color: $primary !important;
    }
}

.form-check-input:checked {
    background-color: $secondary !important;
}

.form-label {
    font-weight: 600;
    color: $body-color;
    width: 100%;
}

.payment-info-form {
    border: solid 1px $primary;
    border-radius: .5rem;
    background-color: #fff;
    padding: 1em 1em 0 1em;
}

.review-bar {
    display: inline-block;
    width: 57px;
    height: 0px;
    border: 0.5px solid $dark-gray;
    margin: 0.5em;
}

.number-circle {
    display: inline-block;
    width: 17px;
    line-height: 1rem;
    border-radius: 50%;
    text-align: center;
    background-color: $primary;
    color: #fff;
    font-size: .675em;
    vertical-align: text-top;
    margin-top: 1px;
    padding-top: 1px;
}

.calendar-card.card {
    display: inline-block;
    border-radius: 0.5rem;
    width: 5rem;
    height: 5rem;;
    .card-header {
        border-radius: 0.5rem 0.5rem 0 0;
        background-color: $primary;
        color: #fff;
        font-size: .675em;
        text-align: center;
        font-weight: bold;
        line-height: .5em;
        padding: 0.675em 0.5em 0.5em 0.5em;
    }
    .card-body {
        padding: 0;
    }
    .card-text {
        text-align: center;
        font-weight: bold;
        font-size: 2.5em;
    }
}

#heavy-items {
    .form-floating select {
        height: 42px;
        padding: 0.75rem;
    }
}

.heavy-items-card {
    border-radius: 8px;
    text-decoration: none;
    transition: color 0.3s ease-in-out, background-color 0.3s ease-in-out, border-color 0.3s ease-in-out, box-shadow 0.3s ease-in-out !important;
    input:checked {
        border-color: $primary !important;
        background-color: $primary !important;
    }
}

.heavy-items-card.active {
    border-color: $primary !important;
    //background-color: var(--bs-notification-light-40) !important;
}

.heavy-items-card.active h3, .heavy-items-card.active .far {
    color: $primary !important;
}