#root>.sml, .modal.sml  {
    --bs-primary-rgb: 229, 109, 37;
    --bs-secondary-rgb: 39, 58, 126;

    $primary: rgb(229, 109, 37);
    $secondary: rgb(39, 58, 126);

    @import 'shared-after';
}

#claimsModal-sml, .offcanvas-lg.sml {
    --bs-primary-rgb: 229, 109, 37;
    --bs-secondary-rgb: 39, 58, 126;

    $primary: rgb(229, 109, 37);
    $secondary: rgb(39, 58, 126);
}