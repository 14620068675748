
$light-gray: #76747B;
$dark-gray: #4D4D4F;
$headings-color: $dark-gray;
$body-color: $dark-gray;
$success: #56C73B;

// $primary: #C4C4C4 !default;
// $secondary: #C4C4C4 !default;

// $theme-colors: (
//   "primary": $primary,
//   "secondary": $secondary
// );

$spacer: 1rem !default;
$spacers: () !default;
// stylelint-disable-next-line scss/dollar-variable-default
$spacers: map-merge((0: 0,
    1: ($spacer * .25),
    2: ($spacer * .5),
    3: $spacer,
    4: ($spacer * 1.5),
    5: ($spacer * 3),
    6: ($spacer * 6),
    7: ($spacer * 9),
    8: ($spacer * 12),
    9: ($spacer * 15),
  ),
  $spacers);

/* $link-color: $text-link-color;
$body-color: $brand-base-color-dark-10; */

// future: this isn't working with webpack, using cdn for now...
// @import "fonts/rubik.scss";
// @import "fonts/roboto.scss";

// Font Stack
$font-family-sans-serif: Roboto,
"Helvetica Neue",
Arial,
sans-serif,
"Apple Color Emoji",
"Segoe UI Emoji",
"Segoe UI Symbol",
"Noto Color Emoji" !default;
$font-family-monospace: Rubik,
SFMono-Regular,
Menlo,
Monaco,
Consolas,
"Liberation Mono",
"Courier New",
monospace !default;

$font-family-base: $font-family-sans-serif;

$font-size-base: 1rem !default; // Assumes the browser default, typically `16px`

$enable-responsive-font-sizes: true;

// Headings
$headings-font-family: $font-family-monospace;

// Buttons
$btn-border-radius: 8px;
$btn-border-radius-lg: 8px;
$btn-border-radius-sm: 8px;
$btn-border-width: 2px;
$btn-font-weight: bold;

$btn-padding-y-lg: 0.75rem;
$btn-padding-x-lg: 1rem;
$btn-font-size-lg: 16px;

$border-color: $light-gray;
$input-border-color: $light-gray;
$input-placeholder-color: $light-gray;
$form-label-color: $light-gray;
$form-floating-label-opacity: 1;
// Forms
$input-focus-box-shadow: none;

// BS 5 sets scroll-behavior: smooth globally, so we may not need to use SweetScroll - but this causes weird interactions? For now - disabling global css rule.
$enable-smooth-scroll: true;
